<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44V40C15.1634 40 8 32.8366 8 24C8 15.1634 15.1634 8 24 8V4Z"
      fill="#120E14"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 24C4 24 4 24 4 24C4 35.0457 12.9543 44 24 44C35.0457 44 44 35.0457 44 24H40C40 32.8366 32.8366 40 24 40C15.1634 40 8 32.8366 8 24L4 24Z"
      fill="#120E14"
      fill-opacity="0.16"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
